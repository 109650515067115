<template>
  <div class="p-8 mt-8 flex flex-col justify-center items-center">
    <div class="p-4">
      <fa-icon
        icon="folder-open"
        :size="iconSize"
        class="text-blue-500"
      />
    </div>
    <div class="text-center text-gray-500 dark:text-gray-200">
      <div
        v-if="hiddenItemCount < 1"
        class="text-xl font-bold mb-1"
      >
        This folder is empty
      </div>
      <div
        v-else
        class="text-xl font-bold mb-1"
      >
        No available assets in this folder
      </div>

      <div class="">
        Drop files here to upload
      </div>
      <div
        v-if="hiddenItemCount > 0"
        class="italic text-sm text-gray-500"
      >
        Only showing <template v-for="type,index in restrictedActionTypes">
          {{ type }}<template v-if="restrictedActionTypes.length >= index">
            ,
          </template>
        </template> folder items. <br>{{ hiddenItemCount }} item<template v-if="hiddenItemCount > 1">
          s
        </template> not currently shown.
      </div>
    </div>
  </div>
</template>
<script>
const ContentUpload = () => import('@modals/ContentUpload')
const SceneCreate = () => import('@modals/SceneCreate')
const SequenceCreate = () => import('@modals/SequenceCreate')
const FolderCreate = () => import('@modals/FolderCreate')

export default {
  name: 'AssetsExplorerCollectionEmptyBlock',

  props: {
    project: {
      required: true,
      type: Object
    },

    activeFolder: {
      required: true,
      type: Object
    },

    hiddenItemCount: {
      type: Number,
      default: 0
    },

    restrictedActionTypes: {
      type: Array,
      default: () => []
    }
  },

  computed: {

    iconSize () {
      if (this.hasRestrictedActionTypes) return '3x'
      return '4x'
    },

    isRoot () {
      return this.activeFolder.parent === null
    },

    hasRestrictedActionTypes () {
      return this.restrictedActionTypes.length > 0
    }

  },

  methods: {
    showModalContentUpload () {
      this.$modal.show(
        ContentUpload, {},
        {
          height: 'auto',
          width: '750px',
          scrollable: true
        })
    },

    showModalFolderCreate () {
      this.$modal.show(
        FolderCreate, {
          parent: this.activeFolder.id,
          project: this.project
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    showModalSceneCreate () {
      this.$modal.show(
        SceneCreate, {}, {
          height: 'auto',
          width: '750px',
          scrollable: true
        })
    },
    showModalSequenceCreate () {
      this.$modal.show(
        SequenceCreate, {
          parent: this.activeFolder.id,
          project: this.project
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    }
  }
}

</script>
